// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override the defaults 
// without modifying versioned files.


$image-bg:             #2266a5 !default;
$figcaption-bg:        #135796 !default;
$figcaption-padding:   30px !default;
$text-color:           #ffffff !default;
$border-color:         #ffffff !default;
$border-margin:        5px !default;
$border-width:         4px !default;
$transition-duration:  0.35s !default;
$shift-distance:       10px !default;